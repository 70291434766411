
import RingLoader from 'vue-spinner/src/RingLoader.vue'

export default {
  name: 'MySpinner',
  components: {
    RingLoader
  },
  props: {
    loading: Boolean
  }
}
